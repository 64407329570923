.bottom-control-panel--bet{
  .center-controls{
    z-index: 0 !important;
    position: absolute !important;
  }

  &__opened{
    .center-controls{
      width: 100%;
      max-width: 58.5rem;
      position: absolute !important;
    }
  }
}
