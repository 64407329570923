ng-scrollbar {
  --scrollbar-Y-track-margin: 0;

  &.autocomplete-list-scrollbar {
    --scrollbar-Y-track-margin: 0.325rem 0;
  }

  .ng-scrollbar-wrapper {
    scrollbar-y {
      margin: var(--scrollbar-Y-track-margin);
    }
  }
}


