.content-wrapper {
  position: relative;
  padding: 16px 0 0;
}

.return-button {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-theme-700);
  color: var(--color-typography-muted-30);
  margin: 0 16px 0 0;
  font-size: 8px;
  border-radius: 8px;

  app-arrow-icon {
    width: 12px;
    height: 12px;
    padding-left: 2px;
  }

  &:hover {
    color: var(--color-typography-light);
  }
}

.app-navigation {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 32px 0 0;
}

.app-navigation-title {
  font-size: 20px;
  font-weight: bold;
}
