//.gray-v2{
:root {
  $gray-100-color: #f7fbfc;
  $gray-150-color: #deedf4;
  $gray-200-color: #b4c5cd;
  $gray-250-color: #a3b4bc;
  $gray-300-color: #93a3ab;
  $gray-350-color: #93a3ab;
  $gray-400-color: #728188;
  $gray-450-color: #606f76;
  $gray-500-color: #2f3c42;
  $gray-550-color: #29343a;
  $gray-600-color: #232d32;
  $gray-650-color: #1d2529;
  $gray-700-color: #181e21;
  $gray-750-color: #121719;
  $gray-800-color: #0c0f10;
  $gray-850-color: #070809;
  $gray-900-color: #0c0c10;
  $gray-950-color: #0c0f10; // TODO: дублирование $gray-800-color

  $blue-100-color: #bfeaff;
  $blue-200-color: #9fdfff;
  $blue-300-color: #80d5ff;
  $blue-400-color: #40bfff;
  $blue-500-color: #17a1e5;
  $blue-600-color: #0095df;
  $blue-700-color: #007fbf;
  $blue-800-color: #006a9f;
  $blue-900-color: #005580;
  $blue-1000-color: #002940;
  $green-300-color: #a4ff94;
  $green-400-color: #48ff29;
  $green-500-color: #34e517;
  $green-600-color: #20df00;
  $green-700-color: #235429;
  $green-800-color: #0e6000;
  $green-900-color: #164014;
  $red-300-color: #ff8878;
  $red-500-color: #ff1c00;
  $red-800-color: #660b00;
  $red-900-color: #330600;
  $yellow-300-color: #ffeca0;
  $yellow-400-color: #ffd941;
  $yellow-500-color: #ffd11a;
  $yellow-700-color: #e1b400;
  $yellow-800-color: #665200;
  $yellow-900-color: #332900;
  $transparent-color: rgb(0 0 0 / 0%);
  $stattrak-color: #ffa469;
  $souvenir-color: #ffd700;
  $joker-color: #e95bd2;
  $theme: (
    gray-100: (
      '': $gray-100-color,
      'a-5': rgba($gray-100-color, 0.05),
      'a-10': rgba($gray-100-color, 0.1),
      'a-60': rgba($gray-100-color, 0.6),
    ),
    gray-150: (
      '': $gray-150-color,
    ),
    gray-200: (
      '': $gray-200-color,
    ),
    gray-250: (
      '': $gray-250-color,
    ),
    gray-300: (
      '': $gray-300-color,
    ),
    gray-350: (
      '': $gray-350-color,
    ),
    gray-400: (
      '': $gray-400-color,
    ),
    gray-450: (
      '': $gray-450-color,
    ),
    gray-500: (
      '': $gray-500-color,
      // light: lighten($gray-500-color, 20%),
      // dark: darken($gray-500-color, 20%),
      translucent: rgba($gray-500-color, 0.9),
      transparent: rgba($gray-500-color, 0.6),
    ),
    gray-550: (
      '': $gray-550-color,
      // light: lighten($gray-550-color, 20%),
      // dark: darken($gray-550-color, 20%),
      translucent: rgba($gray-550-color, 0.9),
    ),
    gray-600: (
      '': $gray-600-color,
      // light: lighten($gray-600-color, 20%),
      // dark: darken($gray-600-color, 20%),
      // translucent: rgba($gray-600-color, 0.9),
    ),
    gray-650: (
      '': $gray-650-color,
      // light: lighten($gray-650-color, 20%),
      // dark: darken($gray-650-color, 20%),
      // translucent: rgba($gray-650-color, 0.9),
    ),
    gray-700: (
      '': $gray-700-color,
      // light: lighten($gray-700-color, 20%),
      // dark: darken($gray-700-color, 20%),
      // translucent: rgba($gray-700-color, 0.9),
    ),
    gray-750: (
      '': $gray-750-color,
      // light: lighten($gray-750-color, 20%),
      // dark: darken($gray-750-color, 20%),
      translucent: rgba($gray-750-color, 0.98),
      modal: rgba($gray-750-color, 0.9),
      transparent: rgba($gray-750-color, 0.6),
    ),
    gray-800: (
      '': $gray-800-color,
      // light: lighten($gray-800-color, 20%),
      // dark: darken($gray-800-color, 20%),
      transparent: rgba($gray-800-color, 0.6),
    ),
    gray-850: (
      '': $gray-850-color,
      // light: lighten($gray-850-color, 20%),
      // dark: darken($gray-850-color, 20%),
      // translucent: rgba($gray-850-color, 0.9),
    ),
    gray-900: (
      '': $gray-900-color,
      // light: lighten($gray-900-color, 20%),
      // dark: darken($gray-900-color, 20%),
      // translucent: rgba($gray-900-color, 0.9),
      transparent: rgba($gray-900-color, 0.5),
      // ,,,
    ),
    gray-950: (
      '': $gray-950-color,
      // light: lighten($gray-950-color, 20%),
      // dark: darken($gray-950-color, 20%),
      // translucent: rgba($gray-950-color, 0.9),
    ),
    blue-300: (
      '': $blue-300-color,
      // light: lighten($blue-300-color, 20%),
      // dark: darken($blue-300-color, 20%),
      // translucent: rgba($blue-300-color, 0.9),
    ),
    blue-400: (
      '': $blue-400-color,
      // light: lighten($blue-400-color, 20%),
      // dark: darken($blue-400-color, 20%),
      // translucent: rgba($blue-400-color, 0.9),
    ),
    blue-500: (
      '': $blue-500-color,
      // light: lighten($blue-500-color, 20%),
      // dark: darken($blue-500-color, 20%),
      transparent: rgba($blue-500-color, 0.6),
    ),
    // blue-600: (
    //   '': $blue-600-color,
    //   light: lighten($blue-600-color, 20%),
    //   dark: darken($blue-600-color, 20%),
    //   translucent: rgba($blue-600-color, 0.9),
    // ),
    blue-700:
      (
        '': $blue-700-color,
        // light: lighten($blue-700-color, 20%),
        // dark: darken($blue-700-color, 20%),
        // translucent: rgba($blue-700-color, 0.9),
      ),
    blue-800: (
      '': $blue-800-color,
    ),
    blue-900: (
      '': $blue-900-color,
    ),
    green-300: (
      '': $green-300-color,
    ),
    green-400: (
      '': $green-400-color,
    ),
    green-500: (
      '': $green-500-color,
    ),
    green-600: (
      '': $green-600-color,
    ),
    green-700: (
      '': $green-700-color,
    ),
    green-800: (
      '': $green-800-color,
    ),
    green-900: (
      '': $green-900-color,
      transparent: rgba($green-900-color, 0.6),
    ),
    red-300: (
      '': $red-300-color,
    ),
    red-500: (
      '': $red-500-color,
    ),
    // red-600: (
    //   '': $red-600-color,
    // ),
    // red-700: (
    //   '': $red-700-color,
    // ),
    red-800:
      (
        '': $red-800-color,
      ),
    red-900: (
      '': $red-900-color,
    ),
    yellow-300: (
      '': $yellow-300-color,
      // light: lighten($yellow-300-color, 20%),
      // dark: darken($yellow-300-color, 20%),
      // translucent: rgba($yellow-300-color, 0.9),
    ),
    yellow-400: (
      '': $yellow-400-color,
      // light: lighten($yellow-400-color, 20%),
      // dark: darken($yellow-400-color, 20%),
      // translucent: rgba($yellow-400-color, 0.9),
    ),
    yellow-500: (
      '': $yellow-500-color,
      // light: lighten($yellow-500-color, 20%),
      // dark: darken($yellow-500-color, 20%),
      // translucent: rgba($yellow-500-color, 0.9),
    ),
    // yellow-600: (
    //   '': $yellow-600-color,
    //   light: lighten($yellow-600-color, 20%),
    //   dark: darken($yellow-600-color, 20%),
    //   translucent: rgba($yellow-600-color, 0.9),
    // ),
    yellow-700:
      (
        '': $yellow-700-color,
        // light: lighten($yellow-700-color, 20%),
        // dark: darken($yellow-700-color, 20%),
        // translucent: rgba($yellow-700-color, 0.9),
      ),
    yellow-800: (
      '': $yellow-800-color,
    ),
    yellow-900: (
      '': $yellow-900-color,
    ),
    transparent: (
      '': $transparent-color,
    ),
    stattrak: (
      '': $stattrak-color,
    ),
    souvenir: (
      '': $souvenir-color,
    ),
    joker: (
      '': $joker-color,
    ),
  );

  @each $name, $color in $theme {
    @if type-of($color) == 'map' {
      @each $subname, $subcolor in $color {
        @if $subname == '' {
          --color-#{$name}: #{$subcolor};
        } @else {
          --color-#{$name}-#{$subname}: #{$subcolor};
        }
      }
    } @else if type-of($color) == 'color' {
      --color-#{$name}: #{$color};
    }
  }
}
