button {
  cursor: pointer;

  &.cdk-keyboard-focused {
    outline: 2px solid var(--color-outline);
  }

  &:disabled {
    color: var(--color-typography-gray-200) !important;
    pointer-events: none;
  }
}

.action-menu-btn {
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 2.75rem;
  height: 2.75rem;
  border-radius: 50%;
  color: var(--color-gray-450);
  background: var(--color-gray-600);

  &:hover {
    color: var(--color-gray-350);
    background: var(--color-gray-550);
  }
}

.btn-group {
  position: relative;
  margin: 0.5rem;

  &>.btn,
  &>.dropdown,
  &>.btn.btn-sm,
  &>.btn.btn-lg {
    margin: 0;
    border-radius: 0;
    position: static;
  }

  &>.btn {
    border-width: 2px;

    &:first-child {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
    }

    &:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }
  }

  &>.dropdown {
    .btn {
      border-radius: 0;
    }

    &:first-child {
      .btn {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
      }
    }

    &:last-child {
      .btn {
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
      }
    }
  }

  .dropdown-list {
    //border-width: 0;
    top: calc(100% - 2px);
  }
}

@mixin btn-mixin($text-color, $background-color, $opacity) {
  color: var(#{$text-color});
  background-color: var(#{$background-color});
  border-color: var(#{$background-color});

  &:hover {
    color: var(#{$text-color});
    background-color: var(#{$background-color}-active);
    border-color: var(#{$background-color}-active);
  }

  &:active {
    color: var(#{$text-color});
    background-color: var(#{$background-color}-active);
    border-color: var(#{$background-color}-active);
  }
}

@mixin btn-mixin-darken($text-color, $background-color, $opacity) {
  color: var(#{$text-color});
  background-color: var(#{$background-color});
  border-color: var(#{$background-color});

  &:hover {
    color: var(#{$text-color});
    background-color: var(#{$background-color}-outline);
    border-color: var(#{$background-color}-outline);
  }

  &:active {
    color: $text-color;
    background-color: $background-color;
    border-color: $background-color;
  }
}

@mixin btn-outline-mixin($text-color, $alter-text-color, $background-color, $opacity) {
  color: var(#{$text-color});
  background-color: var(#{$background-color}-outline);
  border-color: var(#{$background-color});

  &:hover {
    color: var(#{$alter-text-color});
    background-color: var(#{$background-color});
    border-color: var(#{$background-color});
  }

  &:active {
    color: var(#{$alter-text-color});
    background-color: var(#{$background-color}-active);
    border-color: var(#{$background-color});
  }
}

@mixin btn-transparent-mixin($text-color, $border-color) {
  color: var(#{$text-color});
  background-color: transparent;
  border-color: var(#{$border-color});

  &:hover {
    color: var(#{$text-color}-active);
    border-color: var(#{$border-color}-active);
  }

  &:active {
    color: var(#{$text-color}-active);
    border-color: var(#{$border-color}-active);
  }
}

@mixin btn-inversion-mixin($text-color, $hover-text-color, $border-color) {
  color: var(#{$text-color});
  background-color: transparent;
  border-color: var(#{$border-color});

  &:hover {
    color: var(#{$hover-text-color});
    background-color: var(#{$text-color});
    border-color: var(#{$text-color});
  }

  &:active {
    color: var(#{$hover-text-color});
    background-color: var(#{$text-color}-active);
  }
}

@mixin btn-inversion--design-mixin($text-color, $hover-text-color, $background-color) {
  color: var(#{$text-color});
  background-color: var(#{$background-color});
  border-color: var(#{$background-color});

  &:hover {
    color: var(#{$hover-text-color});
    background-color: transparent;
    border-color: var(#{$background-color});
  }

  &:active {
    color: var(#{$hover-text-color});
    background-color: transparent;
    border-color: var(#{$background-color});
  }
}

@mixin btn-transparent--design-mixin($text-color, $hover-text-color, $background-color) {
  color: var(#{$text-color});
  background-color: transparent;
  border-color: transparent;

  &:hover {
    color: var(#{$hover-text-color});
    background-color: var(#{$background-color});
    border-color: var(#{$background-color});
  }

  &:active {
    color: var(#{$hover-text-color});
    background-color: var(#{$background-color});
    border-color: var(#{$background-color});
  }

  &:disabled {
    opacity: 0.9;
    pointer-events: none;
  }
}

@mixin btn--design-mixin($text-color, $background-color, $hover-background-color) {
  color: var(#{$text-color});
  background-color: var(#{$background-color});
  border-color: var(#{$background-color});

  &:hover {
    background-color: var(#{$hover-background-color});
    border-color: var(#{$hover-background-color});
    color: var(#{$text-color});
  }

  &:disabled {
    opacity: 0.9;
    pointer-events: none;
  }

  // &:active {
  //   background-color: var(#{$hover-background-color});
  //   border-color: var(#{$hover-background-color});
  // }
}

@mixin btn-outline--design-mixin($text-color, $background-color) {
  color: var(#{$background-color});
  background-color: transparent;
  border-color: var(#{$background-color});

  &:hover {
    background-color: var(#{$background-color});
    border-color: var(#{$background-color});
    color: var(#{$text-color});
  }

  // &:active {
  //   background-color: var(#{$hover-background-color});
  //   border-color: var(#{$hover-background-color});
  // }
}

.btn {
  position: relative;
  outline: none;
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  padding: 0.75rem 1.25rem;

  // margin: 0.5rem;
  border: 1px solid transparent;
  font-size: 0.875rem;
  line-height: 1.4;
  border-radius: 0.25rem;
  text-decoration: none;
  letter-spacing: 0.6px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    opacity 0.15s ease-in-out, box-shadow 0.15s ease-in-out, transform 0.15s ease-in-out;

  &:hover {
    color: var(--color-typography-dark);
    text-decoration: none;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &-sm {
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
    padding: 0.25rem 1rem;
    height: 2.25rem;

    &.sq {
      padding: 0.25rem;
      aspect-ratio: 1/1;
    }
  }

  &-md {
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    padding: 0.5rem 1.5rem;
    height: 2.75rem;

    &.sq {
      padding: 0.5rem;
      aspect-ratio: 1/1;
    }
  }

  &-lg {
    padding: 0.5rem 1.5rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
    height: 3rem;

    &.sq {
      padding: 0.5rem;
      aspect-ratio: 1/1;
    }
  }

  &-semi-round {
    border-radius: 0.875rem;

    // padding: 0.5rem 1rem;
  }

  &-round {
    border-radius: 1.5rem;

    // padding: 0.5rem 1rem;
  }

  &-semi-round,
  &-round {
    &.sm {
      height: 2.25rem;
    }

    &.md {
      height: 2.75rem;
    }

    &.lg {
      height: 3rem;
    }
  }

  .icon {
    transition: transform 150ms ease-in-out;

    &.sm {
      width: 1rem;
      height: 1rem;
    }

    &.md {
      width: 1.25rem;
      height: 1.25rem;
    }

    &.lg {
      width: 1.5rem;
      height: 1.5rem;
    }

    &.rotate {
      transform: rotate(180deg);
    }
  }

  &.j-sb {
    justify-content: space-between;
  }

  &.j-fs {
    justify-content: flex-start;
  }

  &.w-f {
    // width: 100%;
    flex-grow: 1;
  }

  &.hover-scale {
    &:hover {
      transform: scale(1.125);
    }
  }

  @include btn-mixin(--color-typography-light, --color-theme-800, 20%);

  .icomoon::before {
    transition: color 0.15s ease-in-out;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.btn-link {
  color: var(--color-secondary);
  background-color: transparent;
  border-color: transparent;

  &:hover {
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent;
  }
}

.btn-light {
  @include btn-mixin-darken(--color-theme-200, --color-typography-light, 20%);
}

.btn-dark {
  @include btn-mixin(--color-typography-light, --color-theme-600, 20%);
}

.btn-darken {
  @include btn-mixin(--color-typography-light, --color-theme-400, 20%);
}

.btn-primary {
  @include btn-mixin(--color-typography-dark, --color-primary, 20%);
}

.btn-secondary {
  @include btn-mixin(--color-typography-light, --color-secondary, 20%);
}

.btn-success {
  @include btn-mixin(--color-typography-light, --color-attention-success, 10%);
}

.btn-alert {
  @include btn-mixin(--color-typography-light, --color-attention-error, 15%);
}

.btn-outline {
  @include btn-outline-mixin(--color-typography-light, --color-typography-light, --color-theme-200, 20%);
}

.btn-outline-light {
  @include btn-outline-mixin(--color-theme-200, --color-theme-200, --color-typography-light, 20%);
}

.btn-outline-dark {
  @include btn-outline-mixin(--color-typography-light, --color-typography-light, --color-theme-600, 20%);
}

.btn-outline-primary {
  @include btn-outline-mixin(--color-typography-light, --color-typography-dark, --color-primary, 40%);
}

.btn-outline-secondary {
  @include btn-outline-mixin(--color-typography-light, --color-typography-light, --color-secondary, 20%);
}

.btn-outline-success {
  @include btn-outline-mixin(--color-typography-light, --color-typography-light, --color-attention-success, 20%);
}

.btn-outline-alert {
  @include btn-outline-mixin(--color-typography-light, --color-typography-light, --color-attention-error, 20%);
}

.btn-transparent-primary {
  @include btn-transparent-mixin(--color-primary, --color-primary);
}

.btn-transparent-secondary {
  @include btn-transparent-mixin(--color-secondary, --color-secondary);
}

.btn-inversion-primary {
  @include btn-inversion-mixin(--color-primary, --color-typography-dark, --color-primary);
}

.btn-inversion-secondary {
  @include btn-inversion-mixin(--color-secondary, --color-typography-light, --color-secondary);
}

/**  New design  **/

/**  Yellow btn  **/
.btn-primary--design {
  @include btn--design-mixin(--color-gray-950, --color-yellow-500, --color-yellow-400);
}

.btn-default--design {
  @include btn--design-mixin(--color-gray-100, --color-blue-500, --color-blue-400);
}

.btn-accent--design {
  @include btn--design-mixin(--color-gray-950, --color-green-500, --color-green-400);
}

.btn-basic--design {
  @include btn--design-mixin(--color-gray-100, --color-gray-550, --color-gray-450);
}

.btn-basic-dark--design {
  @include btn--design-mixin(--color-gray-100, --color-gray-650, --color-gray-550);
}

.btn--design {
  --text-color: var(--color-gray-950);
  --bg-color: var(--color-yellow-500);
  --hover-bg-color: var(--color-yellow-400);

  @include btn--design-mixin(--text-color, --bg-color, --hover-bg-color);
}

.btn-outline--design {
  --text-color: var(--color-gray-950);
  --bg-color: var(--color-yellow-500);

  @include btn-outline--design-mixin(--text-color, --bg-color);
}

.btn-transparent-primary--design {
  @include btn-transparent--design-mixin(--color-gray-200, --color-gray-550, --color-yellow-400);
}

.btn-transparent-default--design {
  @include btn-transparent--design-mixin(--color-gray-200, --color-gray-550, --color-blue-400);
}

.btn-transparent-accent--design {
  @include btn-transparent--design-mixin(--color-gray-200, --color-gray-550, --color-green-400);
}

.btn-transparent-basic--design {
  @include btn-transparent--design-mixin(--color-gray-200, --color-gray-100, --color-gray-450);
}

.btn-transparent-dark--design {
  @include btn-transparent--design-mixin(--color-gray-200, --color-gray-100, --color-gray-600);
}

.btn-transparent-darken--design {
  @include btn-transparent--design-mixin(--color-gray-400, --color-gray-200, --color-gray-650);
}

/**  Green btn  **/

.btn-primary-outline-green--design {
  @include btn-outline--design-mixin(--color-gray-950, --color-green-400);
}

.btn-inversion-green--design {
  @include btn-inversion--design-mixin(--color-gray-700, --color-green-500, --color-green-500);
}

/**  Black btn **/

.btn-primary-outline-black--design {
  @include btn-outline--design-mixin(--color-gray-950, --color-gray-950);
}

/**  Yellow btn **/

.btn-inversion-yellow--design {
  @include btn-inversion--design-mixin(--color-gray-700, --color-yellow-500, --color-yellow-500);
}

.btn-primary-outline-yellow--design {
  @include btn-outline--design-mixin(--color-gray-950, --color-yellow-500);
}

/**  Blue btn **/

.btn-primary-blue--design {
  @include btn--design-mixin(--color-gray-100, --color-blue-500, --color-blue-400);
}

.btn-outline-blue--design {
  @include btn-outline--design-mixin(--color-gray-100, --color-blue-500);
}

/**  Gray btn **/

.btn-gray--design {
  @include btn--design-mixin(--color-gray-300, --color-gray-550, --color-gray-500);
}

.btn-darken--design {
  @include btn--design-mixin(--color-gray-400, --color-gray-650, --color-gray-550);
}

.btn-outline-gray--design {
  @include btn-outline--design-mixin(--color-gray-100, --color-gray-400);
}

.btn-outline-darken--design {
  @include btn-outline--design-mixin(--color-gray-150, --color-gray-500);
}

.btn-outline-dark--design {
  @include btn-outline--design-mixin(--color-gray-200, --color-gray-600);
}

.btn-inversion-gray--design {
  @include btn-inversion--design-mixin(--color-gray-150, --color-gray-200, --color-gray-500);
}