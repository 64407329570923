.mat-mdc-select-panel,
.mat-mdc-autocomplete-panel {
  &.ui-menu {
    --accent-color: var(--color-blue-500);
    --background-color: var(--color-gray-700);
    --mat-select-panel-background-color: var(--color-gray-700);
    --options-border: var(--color-gray-600);
    --text-color: var(--color-gray-300);
    --text-hover-color: var(--color-gray-100);
    --border-radius: 0.75rem;
    --max-height: 15.625rem;
    --icon-size: 1.5rem;
  }

  &.trade-select {
    --icon-size: 1rem;
    --max-height: none;

    border: none;

    --border-radius: 1rem;
  }

  gap: 1px;
  width: 100%;
  border: 1px solid var(--accent-color);
  border-radius: var(--border-radius) !important;
  background-color: var(--background-color);
  margin-top: 0.25rem !important;
  padding: 0.25rem !important;
  max-height: var(--max-height) !important;
  max-width: none !important;

  .mat-mdc-option {
    --mat-option-label-text-line-height: 1.15rem;

    position: relative;
    border-radius: calc(var(--border-radius) * 0.75);
    overflow: visible;
    color: var(--text-color);
    margin: 0.075rem 0;
    transition:
      background-color 150ms ease-in-out,
      color 150ms ease-in-out;

    &::after {
      position: absolute;
      top: calc(100% + 0.075rem);
      left: 50%;
      transform: translateX(-50%);
      content: '';
      width: 90%;
      height: 1px;
      background: var(--options-border);
    }

    .mdc-list-item__primary-text {
      display: inline-flex;
      gap: 0.5rem;
      align-items: center;

      .menu-icon {
        margin: 0;
        width: var(--icon-size);
        height: var(--icon-size);

        &.image {
          border-radius: 50%;
          object-fit: fill;
        }
      }
    }

    &-active,
    &.mdc-list-item--selected {
      --mat-option-selected-state-label-text-color: var(--accent-color);

      background-color: var(--options-border);
    }

    &:last-child {
      &::after {
        display: none;
      }
    }

    &:hover {
      --mat-option-hover-state-layer-color: none;
      --mat-option-selected-state-label-text-color: var(--text-hover-color);

      color: var(--text-hover-color);
    }

    .mat-mdc-option-pseudo-checkbox {
      display: none;
    }
  }
}

.trade-select-panel {
  mat-form-field {
    border: none;

    &:hover:not(&:disabled) {
      border: none;
      background-color: var(--color-gray-550) !important;
    }
  }

  .mat-form-field-disabled {
    user-select: none;
    pointer-events: none;
  }
}
